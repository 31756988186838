
  import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
  import { Location } from 'vue-router/types/router'
  import { Route } from 'vue-router'
  import { Link } from '@/store/app/state'
  import { mapActions } from 'vuex'
  import { Query } from '@/entities/public/Resource/interfaces'
  import { formFilter } from '@/graphql/generated-types'

@Component({
  methods: {
    ...mapActions('resources/form', ['fetchData']),
    ...mapActions('app', ['setRole']),
  },
})
  export default class List extends Vue {
  @Prop({ type: Array, default: () => [] }) items!: Array<Link>;
  @Prop({ type: String, default: '' }) search!: string;
  @Prop({ type: Boolean, default: false }) isMini!: boolean;
  @Prop({ type: Boolean, default: false }) disabled!: boolean;
  selected = -1;

  setRole!: (payload: string) => void
  fetchData!: (payload: {
    query: Query
    filter?: formFilter
    offset?: number
    limit?: number
    force?: boolean
    distinct?: Array<string>
  }) => Promise<any>;

  mounted (): void {
    const selected = this.items.findIndex(
      item => item.route.path === this.$route.path || item.route.name === this.$route.name
    )

    if (selected !== -1 && this.items?.[selected]) {
      this.setRole(this.items[selected].slug)
      this.selected = selected
    }
  }

  @Watch('$route', { immediate: true, deep: true })
  async onChangeRoute (val: Route): Promise<void> {
    const role = val.path?.split('/')?.slice(2, 3)[0] // Obtiene el rol de la URL

    const selected = this.items?.findIndex((item: Link) => {
      const matchPath = item.slug === role

      return matchPath || item.route.path === val.path || item.route.name === val.name
    })

    if (selected !== -1 && this.items[selected]) {
      this.setRole(this.items[selected].slug)
      this.selected = selected
    }
  }

  goTo (val: Location): void {
    const { path, name } = val
    this.$router.push(path || { name }).catch(() => {
    })
  }
  }
